import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import SchoolSupportBnImg from '@/assets/img/corporate-and-organization/school_support_bn@2x.png';
import SchoolSupportBnImgM from '@/assets/img/corporate-and-organization/school_support_bnM@2x.png';
import SchoolSupport1 from '@/assets/img/corporate-and-organization/school_support_img1@2x.jpg';
import SchoolSupport1M from '@/assets/img/corporate-and-organization/school_support_img1M@2x.jpg';
import SchoolSupport2 from '@/assets/img/corporate-and-organization/school_support_img2@2x.jpg';
import SchoolSupport2M from '@/assets/img/corporate-and-organization/school_support_img2M@2x.jpg';
import SchoolSupport3 from '@/assets/img/corporate-and-organization/school_support_img3@2x.jpg';
import SchoolSupport3M from '@/assets/img/corporate-and-organization/school_support_img3M@2x.jpg';
import SchoolSupportReference from '@/assets/img/corporate-and-organization/school_support_reference@2x.png';
import SchoolSupportReferenceM from '@/assets/img/corporate-and-organization/school_support_referenceM@2x.png';
import Button from '@/components/Button';
import ButtonMoreView from '@/components/ButtonMoreView';
import ThumbnailCardSample, {
  NewsData,
} from '@/components/Card/ThumbnailCardSample';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import ImageIcon from '@/components/ImageIcon';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import BasicPagination from '@/components/Pagination/BasicPagination';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { BOARD_KIND_SCHOOL } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { SelectItemDefaultData } from '@/helpers/GtmHelper';
import { PageInfo, getCurrentPageInfo } from '@/helpers/PaginationHelper';
import {
  SUPPORT_CATEGORY_ONCE_WORLD,
  SUPPORT_TERM_ONCE,
} from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { SupportStep2Data } from '@/page-blocks/donate/DonateStep2';
import { navigate, PageProps } from 'gatsby';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GtmButtonDonate } from '@/components/Button.gtm';

const SponsorData = [
  {
    id: 1,
    pcImg: SchoolSupport1,
    mobileImg: SchoolSupport1M,
    cardTit: `학교 행사 수익금 기부`,
    title: `바자회 수익금을 기부했어요!`,
    dec: `학생 및 교직원에게 물품을 기부받고 유네스코 평화프로젝트에서 지원금을 받아 바자회를 개최하였습니다. 저희의 수익금이 어려운 상황에 처한 아이들에게 닿아 평화로운 일상으로 돌아올 수 있기를 간절히 바랍니다.`,
    from: `현풍고등학교 Withus`,
  },
  {
    id: 2,
    pcImg: SchoolSupport2,
    mobileImg: SchoolSupport2M,
    cardTit: `학급 또는 교내 모금활동`,
    title: `미얀마 문제도 알리고 모금도 했어요`,
    dec: `미얀마 폭력사태 피해 어린이들을 돕기 위해 학생들이 직접 만든 노래인 '세 손가락'이라는 곡을 만들어 공연을 진행하고, 학생들의 기부금으로 미얀마 긴급구호에 참여했습니다.`,
    from: `서울실용음악고등학교 학생들`,
    link: `https://www.youtube.com/watch?v=whNYIg2jrJU`,
  },
  {
    id: 3,
    pcImg: SchoolSupport3,
    mobileImg: SchoolSupport3M,
    cardTit: `교내외 행사, 공모전으로 받은 상금 기부`,
    title: `대상의 기쁨과 함께 느낀 따뜻함`,
    dec: `영월 청소년 댄스가요 경연대회에서 초등부 대상을 수상하여 받은 상금의 일부를 유니세프에 기부했습니다. 아이들의 결정을 지켜본 지도교사로서 더불어 사는 삶을 실천하려 노력하는 따뜻한 마음을 볼 수 있어 흐뭇하고 행복했습니다.`,
    from: `청령포초등학교 제 47회 졸업생`,
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `특별후원팀`,
    tel: `02-721-5152`,
    email: `major@unicef.or.kr`,
  },
];

const SectionTop = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 0;
  }
  .list-container {
    margin-top: 240px;

    .card-dl {
      margin-bottom: 96px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      & > dt {
        margin-bottom: 64px;
      }

      .view-video {
        color: #1cabe2;
        display: inline-block;
        line-height: 32px;
        font-weight: bold;
        text-decoration: underline;
        position: relative;
        padding-right: 24px;
        margin-top: 8px;

        &:hover {
          text-decoration: none;
        }
        ${ImageIcon} {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    .list-container {
      margin-top: 120px;

      .card-dl {
        & > dt {
          margin-bottom: 24px;
        }
      }
    }
  }
`;

const SponsorCard = styled.div`
  display: flex;
  padding-bottom: 100px;
  background: #e2f0f6;
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 240px;
    position: absolute;
  }

  &::before {
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
  }

  &::after {
    height: 240px;
    background: #b2def1;
    left: 240px;
    bottom: 0;
    border-radius: 0 240px 0 0;
  }

  .col-img,
  .col-dec {
    position: relative;
    z-index: 1;
  }
  .col-img {
    width: 48%;
  }

  .col-dec {
    width: 52%;
    padding-left: 40px;
    padding-top: 56px;
    padding-right: 64px;

    p {
      margin-top: 16px;
    }

    .from {
      margin-top: 32px;
    }
  }

  ${breakpoint(1160)} {
    padding-bottom: 8.62vw;
    margin-bottom: 72px;

    &::before,
    &::after {
      width: 20.69vw;
    }

    &::after {
      height: 20.69vw;
      left: 20.69vw;
      border-radius: 0 20.69vw 0 0;
    }

    .col-dec {
      padding-left: 3.45vw;
      padding-top: 4.83vw;
      padding-right: 5.52vw;
    }
  }
  ${breakpoint(`mobile`)} {
    margin: 0 -20px;
    padding-bottom: 60px;
    flex-wrap: wrap;

    &::before {
      display: none;
    }

    &::after {
      width: 167px;
      height: 167px;
      left: 0;
      border-radius: 0 167px 0 0;
    }

    .col-dec {
      padding: 32px 20px;
      padding-bottom: 0;
    }

    .col-img,
    .col-dec {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
`;

const SchoolSupportBn = styled.div`
  margin-top: 80px;
  background: #1cabe2;

  ${Container} {
    position: relative;
    padding: 96px 20px 139px;

    .col-dec {
      position: relative;
      z-index: 1;
      h2 {
        margin-top: 13px;
      }
      button {
        margin-top: 64px;
        width: 100%;
        max-width: 256px;
      }
    }

    .col-img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: 844px;
      right: -191px;
    }
  }
  ${breakpoint(1160)} {
    margin-top: 6.9vw;

    ${Container} {
      padding: 8.28vw 20px 11.98vw 20px;

      .col-img {
        max-width: 72.76vw;
        right: -16.47vw;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    ${Container} {
      padding: 72px 0 0;
      .col-dec {
        padding: 0 25px;
      }
      .col-img {
        margin-top: 70px;
        max-width: 100%;
        width: 100%;
        position: relative;
        right: auto;
        bottom: auto;
        text-align: right;

        ${Image} {
          display: inline-block;
          max-width: 360px;
        }
      }
      .btn-wrap {
        margin-top: 48px;
        text-align: left;
        ${Button} {
          margin-top: 0;
          max-width: 150px;
        }
      }
    }
  }
`;
const NoticeContainer = styled.div`
  background: #f8f9fa;
  padding: 40px 0;

  .blit-list {
    margin-top: 32px;
  }
`;

const SectionReference = styled(Section)`
  img {
    width: 100%;
  }
  .btn-wrap {
    margin-top: 32px;
    text-align: center;

    ${ButtonMoreView} {
      max-width: 352px;
      width: 100%;
      text-align: left;
    }
  }
`;

const SchoolList: FC<PageProps> = ({ location }) => {
  const [items, setItems] = useState<NewsData[]>([]);
  const [currentPageInfo, setCurrentPageInfo] = useState<PageInfo>();

  // 학교후원 목록 로드
  const loadItems = useCallback(async () => {
    try {
      const {
        totalCount,
        data,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_SCHOOL],
        page: Number(location.pathname.split('/').at(-1)),
        pagePerCount: 12,
      });

      // 페이지네이션 정보
      const schoolTotalCount = totalCount as number;
      const pageInfo = getCurrentPageInfo({
        totalItemCount: schoolTotalCount,
        baseUri: '/involve/corporate-and-organization/school-support',
        pageGroupSize: 5,
        currentPage: Number(location.pathname.split('/').at(-1)),
        itemsPerPage: 12,
      });
      setCurrentPageInfo(pageInfo);

      // 학교 목록
      const schoolSupport = data as any;
      setItems(
        schoolSupport.map((school: BoardVo) => ({
          id: school.boardIndexNumber,
          boardIndexNumber: school.boardIndexNumber || ``,
          imgAttGrpNoTn: school.imgAttGrpNoTn,
          subject: school.subject,
          firstRegisterDate: school.firstRegisterDate,
          contents: school.contents,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, [location.pathname]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const supportTerm = SUPPORT_TERM_ONCE; // 후원방법
  const supportCategory = SUPPORT_CATEGORY_ONCE_WORLD; // 후원 분야
  const supporterType = `group`; // 후원자 유형
  const getSupportPageUrl = (memberTypeCode: string) => {
    return `/donate/?supportTerm=${supportTerm}&supportCategory=${supportCategory}&supporterType=${supporterType}&memberTypeCode=${memberTypeCode}`;
  };

  return (
    <LayoutWithTitle
      location={location}
      title="학교후원"
      description="for every child, partnerships"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  학급, 동아리, 전교생, 교직원, 학부모 등{` `}
                  <br className="small-hide" />
                  누구나 학교에서 유니세프 기금을 모을 수 있습니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                세계 곳곳에서 가난과 전쟁, 기후변화 등으로 고통받는 친구들을
                생각하며 다양한 활동을 통해 기금을 모아보세요.
                <br />
                세계친구들에게 희망을 전하며 나눔의 기쁨도 함께 누릴 수
                있습니다.
              </PageComponent>
            </p>
          </SectionHeader>

          <div className="list-container">
            {SponsorData.map((row) => (
              <dl className="card-dl" key={row.id}>
                <dt>
                  <Tit size="s2">{row.cardTit}</Tit>
                </dt>
                <dd>
                  <SponsorCard>
                    <div className="col-img">
                      <Image pcSrc={row.pcImg} mobileSrc={row.mobileImg} />
                    </div>
                    <div className="col-dec">
                      <Tit size="s3" color="sky">
                        {row.title}
                      </Tit>
                      <p className="dec">{row.dec}</p>
                      {row.link ? (
                        <LinkSafe
                          to={`${row.link}`}
                          className="view-video"
                          target="_blank"
                        >
                          영상 보러가기 <ImageIcon name="target" />
                        </LinkSafe>
                      ) : (
                        ``
                      )}
                      <p className="from">{row.from}</p>
                    </div>
                  </SponsorCard>
                </dd>
              </dl>
            ))}
          </div>
        </Container>
      </SectionTop>

      <Section className="by-sub-main-layout">
        <SchoolSupportBn>
          <Container>
            <div className="col-dec">
              <Tit size="s4" color="yellow">
                지구촌 곳곳의 어린이가 꿈을 키워나갈 수 있도록
              </Tit>
              <h2>
                <Tit size="s1-5" color="white" weight="normal">
                  다양한 활동을 통해 모은 <br />
                  기금을 유니세프에 전해주세요
                </Tit>
              </h2>
              <div className="btn-wrap">
                <GtmButtonDonate
                  type="button"
                  text="후원하기"
                  title="후원하기-학교후원"
                  color="yellow"
                  full
                  goto={getSupportPageUrl('SCH')}
                  gtmInfo={{
                    ...SelectItemDefaultData,
                    itemId: 'D201',
                    itemName: '세계 어린이 돕기',
                    itemCategory: SUPPORT_TERM_ONCE,
                  }}
                  onClickPreEvent={() => {
                    // TODO onClick before call gtm
                    // alert('click');
                  }}
                />
              </div>
            </div>
            <div className="col-img">
              <Image
                pcSrc={SchoolSupportBnImg}
                mobileSrc={SchoolSupportBnImgM}
              />
            </div>
          </Container>
        </SchoolSupportBn>
        <NoticeContainer>
          <Container>
            <Tit size="s1-5">후원 시 안내사항</Tit>
            <ul className="blit-list">
              <li>별도의 회원가입 없이 진행하실 수 있습니다.</li>
              <li>학교(단체)의 정기후원 문의는 02-737-1004 입니다.</li>
              <li>
                감사장 및 후원금 납입 영수증은 입력하시는 단체명으로 발급하여
                이메일로 발송해드립니다(후원내역 확인 후 7일이내).
              </li>
              <li>
                무통장입금(가상계좌)으로 후원 시, 기재 금액과 입금액이 불일치
                또는 지정일까지 미입금 시 후원이 완료되지 않아 후원정보 재입력
                후 진행하셔야 합니다.
              </li>
            </ul>
          </Container>
        </NoticeContainer>
      </Section>

      <SectionReference className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5">
                다양한 유니세프 자료를 <br />
                모금 활동에 활용해보세요!
              </Tit>
            </h2>
          </SectionHeader>
          <Image
            pcSrc={SchoolSupportReference}
            mobileSrc={SchoolSupportReferenceM}
          />
          <div className="btn-wrap">
            <ButtonMoreView
              mode="sky"
              onClick={() => {
                navigate(`/what-we-do/database`);
              }}
            >
              자료 보러가기
            </ButtonMoreView>
          </div>
        </Container>
      </SectionReference>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2 id="school-list">
              <Tit size="s1">참여학교 소식</Tit>
            </h2>
          </SectionHeader>
          <ThumbnailCardSample
            newsLists={items}
            popUp
            popTitle="참여학교 소식"
          />
          <BasicPagination {...currentPageInfo} hash="school-list" />
        </Container>
      </SectionNews>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default SchoolList;
